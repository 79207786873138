<template>
  <p class="-tracking-1 sm:text-xl">{{ t("note") }}</p>
</template>
<script lang="ts" setup>
const { t } = useI18n({
  useScope: "local",
  messages: {
    cs: {
      note: "Všechny ceny jsou uvedeny bez DPH (21 %).",
    },
    en: {
      note: "All listed prices are without VAT (21 %).",
    },
  },
});
</script>
